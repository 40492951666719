import React, { useState, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { ModalThumbnail } from '../ModalThumbnail/ModalThumbnail'
import { Thumbnail } from '../Thumbnail/Thumbnail'
import { PillSizeSvg } from '../../_svgs/PillSizeSvg/PillSizeSvg'
import { ProductFlag } from '../../ProductFlag/ProductFlag'
import { LightBox } from '../../LightBox/LightBox'
import { TransparentButton } from '../../_inputs/Button/Button'
import { PlayIcon } from '../../_svgs/PlayIcon/PlayIcon'
import { Expand } from '../../_svgs/Expand/Expand'
import { ZoomImage } from '../ZoomImage/ZoomImage'

export const ProductImageGallery = (props) => {
  console.log('product', props)
  const [image, setImage] = useState({ ending: '', alt: 'woot' })
  const [showEnlargedModal, setShowEnlargedModal] = useState(false)
  const [videoId, setVideoId] = useState('')

  const openHandler = (videoID) => {
    setImage({ ending: '', alt: 'woot' })
    setShowEnlargedModal(true)
    setVideoId(videoID)
  }

  const thumbnailClickHandler = (imageObj) => {
    setImage(imageObj)
    if (videoId) {
      setVideoId('')
    }
  }
  let extraImages = []
  let modalExtraImages = []
  for (let x = 1; x <= props.extraImages; x++) {
    modalExtraImages.push(
      <ModalThumbnail
        key={`thumbnail${x}`}
        clickHandler={thumbnailClickHandler}
        ending={`_${x}`}
        current={image.ending}
        productPartNumber={props.productPartNumber}
        alt={`product image extra ${x}`}
      />
    )
    extraImages.push(
      <Thumbnail
        key={`thumbnail${x}`}
        clickHandler={thumbnailClickHandler}
        ending={`_${x}`}
        current={image.ending}
        productPartNumber={props.productPartNumber}
        alt={`product image extra ${x}`}
      />
    )
  }

    const cleanedFlagArray = useMemo(() => {
        console.log('Object.keys(props?.product?.flagMap)', Object.keys(props?.product?.flagMap))
        const cleaned = Object.keys(props?.product?.flagMap)?.filter((flag) => {
            return flag === 'reward2x' || flag || 'reward4x' || flag === 'reward3x' || flag === 'new_improved' || flag === 'webnew';
        })
        console.log('cleaned', cleaned)
        if (props?.product?.bogo) {
            cleaned.push('bogo');
        }
        if (props?.product?.outletmall) {
            cleaned.push('outletmall');
        }
        return cleaned;
    }, [props?.product?.flagMap, props?.product?.bogo, props?.product?.outletmall]);

  console.log('cleanedFlagArray on gallery', cleanedFlagArray)

    return (
    <Container>
        {props?.flagMap &&
            <FlagContainerDiv>
                {cleanedFlagArray?.map((flag, index) => {
                  return (
                      <ProductFlag
                        key={`flag-${index}`}
                        newImproved={flag === 'new_improved'}
                        bogo={flag === 'bogo'}
                        newWebItem={flag === 'webnew'}
                        outletmall={flag === 'outletmall'}
                        rewards2x={flag === 'reward2x'}
                        rewards3x={flag === 'reward3x'}
                        rewards4x={flag === 'reward4x'}
                      />
                  )
                })}
            </FlagContainerDiv>
        }
      <ThumbnailContainer>
        <Thumbnail
          clickHandler={thumbnailClickHandler}
          ending=''
          current={image.ending}
          productPartNumber={props.productPartNumber === 'DELIVERYINS' ? 'DELIVERYINS01' : props.productPartNumber}
          alt='product image'
        />
        {extraImages}
        {props.old &&
          <Thumbnail
            clickHandler={thumbnailClickHandler}
            ending='_old'
            current={image.ending}
            productPartNumber={props.productPartNumber}
            alt='product image old version'
          />
        }
        {props.pillSize !== '' &&
          <Thumbnail
            clickHandler={thumbnailClickHandler}
            ending='pillSize'
            current={image.ending}
            productPartNumber={props.productPartNumber}
            alt='pill size thumbnail'
            pillSize={props.pillSize}
          />
        }
        {props.videoID &&
          <VideoThumbnailButton className='pdpYouTube' data-tracking={props.videoID} onClick={() => { openHandler(props.videoID) }}>
            <VideoSVGContainer><PlayIcon /></VideoSVGContainer>
            <VideoThumbnailImg src={`https://img.youtube.com/vi/${props.videoID}/sddefault.jpg`} alt={`${props.alt} Video`} />
          </VideoThumbnailButton>
        }
      </ThumbnailContainer>
      <PageImageContainer onClick={() => { setShowEnlargedModal(true) }}>
        {image.ending === 'pillSize' && props.pillSize !== '' &&
          <PillSizeSvg pillSize={props.pillSize} />
        }
        {props.old && image.ending === '' &&
          <NewLookImage src='https://media.swansonvitamins.com/images/common/icons/New-Look.png' alt='New Look Same Formula' />
        }
        {image.ending !== 'pillSize' && videoId === '' &&
          <MainImage
            data-testid='mainImage'
            src={`https://media.swansonvitamins.com/images/items/master/${props.productPartNumber === 'DELIVERYINS' ? 'DELIVERYINS01' : props.productPartNumber}${image.ending}.jpg`}
            alt={props.alt}
            height='320'
            width='320'
          />
        }
        <ExpandContainerBtn>
          <Expand />
        </ExpandContainerBtn>
      </PageImageContainer>
      <LightBox
        productTitle={props.productTitle}
        show={showEnlargedModal}
        closeHandler={() => {
          setShowEnlargedModal(!showEnlargedModal)
          if (videoId) {
            setVideoId('')
          }
        }}
        styles={css`
              padding: 2.4rem;
              width: 100%;
              display: flex;
              flex-direction: column;
              border-radius: 0.8rem;
              @media (max-width: ${props => props.theme.breakpoints.md}) {
                max-width: 50rem;
              }
              @media (min-width: ${props => props.theme.breakpoints.lg}) {
                max-width: 70rem;
              }
            `}
        xStyles={`
          position: relative;
        `}
      >
        <ModalContentContainerDiv>
          {videoId
            ? <YouTubeModalContainerDiv>
              <iframe
                width='560'
                height='315'
                src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1&rel=0`}
                title='YouTube video player'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
                id={videoId}
              />
            </YouTubeModalContainerDiv>
            : <ModalImageContainerDiv>
              {(image.ending === 'pillSize' && props.pillSize !== '') &&
                <PillSizeSvg pillSize={props.pillSize} />
              }
              {image.ending !== 'pillSize' && videoId === '' &&
                <>
                  <ZoomImage
                    image={{
                      src: `https://media.swansonvitamins.com/images/items/master/${props.productPartNumber === 'DELIVERYINS' ? 'DELIVERYINS01' : props.productPartNumber}${image.ending}.jpg`,
                      alt: props.alt,
                      width: 550,
                      height: 550
                    }}
                    zoomLevel={2}
                  />
                </>
              }
              {videoId === '' &&
                <ZoomViewerHintDiv>
                  Click or Tap Image to Zoom
                </ZoomViewerHintDiv>
              }
            </ModalImageContainerDiv>
          }
          <ThumbnailContainer>
            <ModalThumbnail
              clickHandler={thumbnailClickHandler}
              ending=''
              current={image.ending}
              productPartNumber={props.productPartNumber === 'DELIVERYINS' ? 'DELIVERYINS01' : props.productPartNumber}
              alt='product image'
            />
            {modalExtraImages}
            {props.old &&
              <ModalThumbnail
                clickHandler={thumbnailClickHandler}
                ending='_old'
                current={image.ending}
                productPartNumber={props.productPartNumber}
                alt='product image old version'
              />
            }
            {props.pillSize !== '' &&
              <ModalThumbnail
                clickHandler={thumbnailClickHandler}
                ending='pillSize'
                current={image.ending}
                productPartNumber={props.productPartNumber}
                alt='pill size thumbnail'
                pillSize={props.pillSize}
              />
            }
            {props.videoID &&
              <ModalVideoThumbnailButton className='pdpYouTube' data-tracking={props.videoID} onClick={() => { openHandler(props.videoID) }}>
                <VideoSVGContainer><PlayIcon /></VideoSVGContainer>
                <ModalVideoThumbnailImg src={`https://img.youtube.com/vi/${props.videoID}/sddefault.jpg`} alt={`${props.alt} Video`} />
              </ModalVideoThumbnailButton>
            }
          </ThumbnailContainer>
        </ModalContentContainerDiv>
      </LightBox>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 1rem;
  position: relative;
  margin-right: auto;
  align-items: center;
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: row;
    align-items: start;
  }
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    margin-top: 1.5rem;
    justify-content: start;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-left: auto;
  }
`

export const FlagContainerDiv = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5rem;
`

const NewLookImage = styled.img`
  position: absolute;
  right: 3rem;
  top: 1rem;
  width: 6rem;
`

const ThumbnailContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: row;
    padding-top: 1.6rem;
  }
`

const MainImage = styled('img').withConfig({
  shouldForwardProp: (prop) => ![].includes(prop)
})`
  height: auto;
  cursor: pointer;
  width: 100%;
  width: 40.5rem;
  height: 40.5rem;
  align-self: center;
  @media (max-width: ${props => props.theme.breakpoints.sm}){
    width: 23.5rem;
    height: 23.5rem;
  }
`

const ModalImageContainerDiv = styled.div`
  position: relative;
  display: flex;
  border: 1px solid ${props => props.theme.mediumGrey};
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  width: 27.2rem;
  height: 35.4rem;
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    width: 55rem;
    height: 58.4rem;
  }
`

const VideoThumbnailButton = styled(TransparentButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 8.5rem;
  height: 8.5rem;
  padding: .6rem;
  @media (min-width: ${props => props.theme.breakpoints.md}){
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 5.5rem;
    height: 5.5rem;
  }
`

const VideoThumbnailImg = styled.img`
  display: flex;
  width: 8.5rem;
  height: 8.5rem;
  @media (max-width: ${props => props.theme.breakpoints.sm}){
    width: 5.5rem;
    height: 5.5rem;
  }
`

const VideoSVGContainer = styled.div`
  position: absolute;
`

const PageImageContainer = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-bottom: 2rem;
    width: 23.5rem;
    height: 24.1rem;
  }
  @media (min-width: ${props => props.theme.breakpoints.sm} and max-width: ${props => props.theme.breakpoints.xl}) {
    width: 40.5rem;
    height: 41.1rem;
  }
  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    padding-left: 12rem;
    padding-right: 12rem;
    width: 64.5rem;
    height: 41.1rem;
  }
`

const ExpandContainerBtn = styled.button`
  position: absolute;
  right: -3.5rem;
  bottom: 8rem;
  background: transparent;
  border: none;
  cursor: pointer;
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    bottom: 0rem;
  }
  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    right: 4rem;
  }
`

const YouTubeModalContainerDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
`

const ModalContentContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: row;
  }
`

const ZoomViewerHintDiv = styled.div`
  margin-top: 1rem;
  text-align: center;
`

const ModalVideoThumbnailButton = styled(TransparentButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 13.5rem;
  height: 13.5rem;
  padding: .6rem;
  @media (min-width: ${props => props.theme.breakpoints.md}){
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 6.5rem;
    height: 6.5rem;
  }
`

const ModalVideoThumbnailImg = styled.img`
  display: flex;
  width: 13.5rem;
  height: 13.5rem;
  @media (max-width: ${props => props.theme.breakpoints.sm}){
    width: 6.5rem;
    height: 6.5rem;
  }
`
