import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const Pill = ({
  pillSize = 4
}) => {
  return (
    <Svg x='0px' y='0px' viewBox='0 0 250 250' pillWidth={pillSize + 'rem'} pillHeight={pillSize + 'rem'}>
      <title>Pill Size</title>
      <Path d='M38.3,110.7c-16.9,8.6-28.4,16.2-34.2,32.5c-9.6,27.1,4.5,57,31.7,66.6c12.1,4.3,24.8,3.9,35.9-0.4l0.5-0.2
                      c2.3-0.9,67.4-32,67.4-32L95.1,83.7L38.3,110.7z' />
      <Path d='M212.1,44.9c-16.3-5.8-29.5-1.6-46.8,6l-56.9,27l44.4,93.6c0,0,65.3-30.8,67.4-32l0.5-0.3
                              c10.3-6,18.7-15.5,23-27.6C253.4,84.4,239.2,54.6,212.1,44.9z M188.4,76.2l-33.5,15.8c-3.5,1.6-7.6,0.1-9.2-3.3
                              c-1.6-3.5-0.1-7.6,3.3-9.2l33.5-15.8c3.5-1.6,7.6-0.1,9.2,3.3C193.4,70.4,191.9,74.5,188.4,76.2z'
      />
    </Svg>
  )
}

const Svg = styled(SvgAttr)`
  height: ${props => props.pillHeight};
  width: ${props => props.pillWidth};
`

const Path = styled.path`
  fill: ${props => props.theme.mediumGrey};
`
