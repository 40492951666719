import React, { useState } from 'react'
import styled from 'styled-components'

export const ZoomImage = ({
  zoomLevel = 2,
  image
}) => {
  const [[posX, posY], setXY] = useState([0, 0])
  const [[imgWidth, imgHeight], setSize] = useState([0, 0])
  const [showMagnifier, setShowMagnifier] = useState(false)
  const magnifierHeight = 1200
  const magnifierWidth = 1200
  const { src, alt, width, height } = image

  const zoomMouseEnterHandler = (e) => {
    if (showMagnifier === false) {
      if (window) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'instant'
        })
      }
      const elem = e.currentTarget
      const { width, height } = elem.getBoundingClientRect()
      setSize([width, height])
      setShowMagnifier(true)
    } else {
      setShowMagnifier(false)
    }
  }

  const mouseMoveHandler = (e) => {
    const elem = e.currentTarget
    const { top, left } = elem.getBoundingClientRect()
    let x = e.pageX - left
    let y = e.pageY - top
    setXY([x, y])
  }

  return (
    <ZoomContainer
      onClick={zoomMouseEnterHandler}
      onMouseMove={mouseMoveHandler}
      onMouseLeave={() => setShowMagnifier(false)}
      onTouchStart={zoomMouseEnterHandler}
      onTouchMove={mouseMoveHandler}
      onTouchEnd={() => setShowMagnifier(false)}
      onTouchCancel={() => setShowMagnifier(false)}
      styles={`
            cursor: ${showMagnifier ? 'zoom-out' : 'zoom-in'};
          `}
    >
      <ModalMainImage
        data-testid='enlargedImage'
        src={src}
        height={height}
        width={width}
        alt={alt}
      />
      {showMagnifier &&
        <ZoomedImage
          data-testid='zoomedInImage'
          styles={`
            height: ${magnifierHeight}px;
            width: ${magnifierWidth}px;
            top: ${posY - magnifierHeight / 2}px;
            left: ${posX - magnifierWidth / 2}px;
            background-image: url('${src}');
            background-size: ${imgWidth * zoomLevel}px ${imgHeight * zoomLevel}px;
            background-position-x: ${-posX * zoomLevel + magnifierWidth / 2}px;
            background-position-y: ${-posY * zoomLevel + magnifierHeight / 2}px;
          `}
        />
      }
    </ZoomContainer>
  )
}

const ZoomContainer = styled.div`
  display: flex;
  align-items: center;
  width: 550px;
  position: relative;
  height: 100%;
  @media (max-width: ${props => props.theme.breakpoints?.md}) {
    width: 320px;
  }
  ${props => props.styles};
`

const ModalMainImage = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
`

const ZoomedImage = styled.div`
  opacity: 1;
  position: absolute;
  pointer-events: none;
  border: 1px solid ${props => props.theme.lightGrey};
  background-color: ${props => props.theme.white};
  background-repeat: no-repeat;
  ${props => props.styles};
`
