import React from 'react'
import styled from 'styled-components'

export const Expand = ({
  height = '6rem',
  width = '6rem'
}) => {
  return (
    <svg width={width} height={height} viewBox='0 0 58 57' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <Path d='M50.5107 7.07108L39.3572 10.0597L47.5221 18.2246L50.5107 7.07108ZM32.1259 26.8701L44.8539 14.1421L43.4396 12.7279L30.7117 25.4559L32.1259 26.8701Z' fill='#545454' />
      <Path d='M50.5107 50.2547L39.3572 47.2661L47.5221 39.1012L50.5107 50.2547ZM32.1259 30.4557L44.8539 43.1837L43.4396 44.5979L30.7117 31.8699L32.1259 30.4557Z' fill='#545454' />
      <Path d='M7.07108 7.1525L18.2246 10.1411L10.0597 18.3061L7.07108 7.1525ZM25.4559 26.9515L12.7279 14.2236L14.1421 12.8094L26.8701 25.5373L25.4559 26.9515Z' fill='#545454' />
      <Path d='M7.07108 50.1733L18.2246 47.1847L10.0597 39.0198L7.07108 50.1733ZM25.4559 30.3743L12.7279 43.1022L14.1421 44.5165L26.8701 31.7885L25.4559 30.3743Z' fill='#545454' />
    </svg>
  )
}

const Path = styled.path`
  fill: ${props => props.theme.darkGrey}
`
