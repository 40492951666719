import React from 'react'
import { SvgAttr } from '../SvgAttr/SvgAttr'
import styled from 'styled-components'

export const PlayIcon = ({
  color = '#fff',
  width = '35px',
  height = '35px'
}) => {
  return (
    <Svg
      viewBox='0 0 72 72'
      width={width}
      height={height}
    >
      <Circle color={color} cx='35.77' cy='35.06' r='28.02'/>
      <g>
        <Path color={color} d='M49.34,33.41c1.66,1,1.66,2.64,0,3.64l-19.5,11.78c-1.66,1-3.01,0.24-3.01-1.7V23.33c0-1.94,1.36-2.7,3.01-1.7L49.34,33.41   z'/>
      </g>
    </Svg>
  )
}

const Svg = styled(SvgAttr)`
  height: ${props => props.height};
  width: ${props => props.width};
`

const Circle = styled.circle`
  fill: none;
  stroke: ${props => props.color};
  stroke-width: 3;
  stroke-miterlimit: 10;
`

const Path = styled.path`
  fill: ${props => props.color};
`