import React from 'react'
import styled from 'styled-components'
import { Pill } from '../../_svgs/Pill/Pill'
import { useMediaQuery } from 'react-responsive'

export const ModalThumbnail = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 576})

  const thumbnailClickHandler = () => {
    props.clickHandler({ ending: props.ending, alt: props.alt })
  }

  return (
    <ThumbnailButton onClick={thumbnailClickHandler}
      data-test-id='thumbnailButton'
      ending={props.ending}
      current={props.current}
      className='pdpFeatureAct modalThumbnail'
    >
      {props.ending === 'pillSize'
        ? <PillThumbnail>
          <Pill pillSize={isMobile ? 4 : 8} />
          <Span>Pill Size</Span></PillThumbnail>
        : <ThumbnailImage
          src={`https://media.swansonvitamins.com/images/items/250/${props.productPartNumber}${props.ending}.jpg`}
          alt={props.alt}
          width='125'
          height='125'
        />
      }

    </ThumbnailButton>
  )
}

const ThumbnailButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  background-color: transparent;
  border: ${props => (props.current === props.ending ? props.theme.black : props.theme.mediumGrey)} 0.1rem solid;
  width: 13.5rem;
  height: 13.5rem;
  padding: .6rem;
  box-sizing: border-box;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-left: 1rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 6.5rem;
    height: 6.5rem;
  }
`

const PillThumbnail = styled.span`
  display:flex;
  flex-direction:column;
  color: ${props => props.theme.black};
  align-items: center;
`

const ThumbnailImage = styled.img`
  height: 12.5rem;
  width: 12.5rem;
  @media (max-width: ${props => props.theme.breakpoints.sm}){
    width: 6rem;
    height: 6rem;
  }
`

const Span = styled.span`
  line-height: 1.2rem;
  white-space: nowrap;
  font-size: 1.6rem
`
