import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const PillSizeSvg = ({ pillSize, thumbnail }) => {
  return (
    <PillSizeContainer thumbnail={thumbnail}>
      {pillSize === '00' &&
      <Svg width='101px' height='31px' viewBox='0 0 101 31'>
        <title>Capsule Size 000</title>
        <PillPathing d='M89.7,0.5H10.6C5,0.5,0.5,7.2,0.5,15.4 c0,8.2,4.5,14.9,10.1,14.9h79.1c5.6,0,10.1-6.7,10.1-14.9C99.8,7.2,95.3,0.5,89.7,0.5z' />
      </Svg>
      }
      {pillSize === 'OO' &&
      <Svg width='87px' height='31px' viewBox='0 0 87 31'>
        <title>Capsule Size 00</title>
        <PillPathing
          d='M76.9,0.5H9.1c-4.8,0-8.7,6.7-8.7,14.9 c0,8.2,3.9,14.9,8.7,14.9h67.8c4.8,0,8.7-6.7,8.7-14.9C85.6,7.2,81.7,0.5,76.9,0.5z' />
      </Svg>
      }
      {pillSize === 'O' &&
      <Svg width='80px' height='28px' viewBox='0 0 80 28'>
        <title>Capsule Size 0</title>
        <PillPathing d='M71.5,0.5H8.2c-4.2,0-7.7,6-7.7,13.4 c0,7.4,3.4,13.4,7.7,13.4h63.3c4.3,0,7.7-6,7.7-13.4C79.2,6.5,75.7,0.5,71.5,0.5z' />
      </Svg>
      }
      {pillSize === '1' &&
      <Svg width='75px' height='27px' viewBox='0 0 75 27'>
        <title>Capsule Size 1</title>
        <PillPathing d='M66.2,0.5h-58C4,0.5,0.5,6.2,0.5,13.3S4,26.1,8.2,26.1h58 c4.2,0,7.7-5.7,7.7-12.8S70.5,0.5,66.2,0.5z' />
      </Svg>
      }
      {pillSize === '2' &&
      <Svg width='70px' height='24px' viewBox='0 0 70 24'>
        <title>Capsule Size 2</title>
        <PillPathing d='M61.8,0.4H7.5c-3.9,0-7.1,5.1-7.1,11.5 c0,6.4,3.2,11.5,7.1,11.5h54.3c3.9,0,7.1-5.2,7.1-11.5C68.9,5.6,65.7,0.4,61.8,0.4z' />
      </Svg>
      }
      {pillSize === '3' &&
      <Svg width='61.6px' height='23px' viewBox='0 0 61.6 23'>
        <title>Capsule Size 3</title>
        <PillPathing d='M54.9,0.6H6.7c-3.4,0-6.1,4.8-6.1,10.8 c0,6,2.7,10.8,6.1,10.8h48.3c3.4,0,6.1-4.8,6.1-10.8C61,5.4,58.3,0.6,54.9,0.6z' />
      </Svg>
      }
      {pillSize === '4' &&
      <Svg width='56.6px' height='21.7px' viewBox='0 0 56.6 21.7'>
        <title>Capsule Size 4</title>
        <PillPathing d='M50.3,0.5h-44c-3.2,0-5.8,4.6-5.8,10.3 c0,5.7,2.6,10.3,5.8,10.3h44c3.2,0,5.8-4.6,5.8-10.3C56.1,5.2,53.5,0.5,50.3,0.5z' />
      </Svg>
      }
      {pillSize === 'A' &&
      <Svg width='31.8px' height='31.8px' viewBox='0 0 31.8 31.8'>
        <title>Softgel Size A</title>
        <PillPathing as='circle' cx='15.9' cy='15.9' r='15.4' />
      </Svg>
      }
      {pillSize === 'B' &&
      <Svg width='36.9px' height='19.6px' viewBox='0 0 36.9 19.6'>
        <title>Softgel Size B</title>
        <PillPathing as='ellipse' cx='18.5' cy='9.8' rx='18' ry='9.3' />
      </Svg>
      }
      {pillSize === 'C' &&
      <Svg x='0px' y='0px' width='44px' height='26px' viewBox='0 0 44 26'>
        <title>Softgel Size C</title>
        <PillPathing as='ellipse' cx='22' cy='13' rx='21.5' ry='12.5' />
      </Svg>
      }
      {pillSize === 'D' &&
      <Svg width='56.8px' height='35px' viewBox='0 0 56.8 35'>
        <title>Softgel Size D</title>
        <PillPathing as='ellipse' cx='28.4' cy='17.5' rx='27.9' ry='17' />
      </Svg>
      }
      {pillSize === 'E' &&
      <Svg x='0px' y='0px' width='63.2px' height='38.2px' viewBox='0 0 63.2 38.2'>
        <title>Softgel Size E</title>
        <PillPathing as='ellipse' cx='31.6' cy='19.1' rx='31.1' ry='18.6' />
      </Svg>
      }
      {pillSize === 'F' &&
      <Svg x='0px' y='0px' width='63px' height='40px' viewBox='0 0 63 46'>
        <title>Softgel Size F</title>
        <PillPathing as='ellipse' cx='31.3' cy='22.6' rx='30.8' ry='22.1' />
      </Svg>
      }
      {pillSize === 'G' &&
      <Svg width='81' height='50'>
        <title>Softgel Size G</title>
        <PillPathing as='ellipse' cx='39.9' cy='19.1' rx='39.4' ry='18.6' />
      </Svg>
      }
      {pillSize === 'H' &&
      <Svg width='100' height='40'>
        <title>Softgel Size H</title>
        <PillPathing as='ellipse' cx='49.6' cy='19.4' rx='49.1' ry='18.9' />
      </Svg>
      }
      {pillSize === 'J' &&
      <Svg width='75' height='33'>
        <title>Tablet Size J</title>
        <PillPathing as='circle' cx='15.6' cy='15.6' r='15.1' />
        <PillPathing d='M50.1,10.7c-8.4,0-15.2,0.8-15.2,1.8v6.2c0,1,6.8,1.8,15.2,1.8s15.2-0.8,15.2-1.8v-6.2 C65.3,11.5,58.5,10.7,50.1,10.7z' />
      </Svg>
      }
      {pillSize === 'K' &&
      <Svg width='83' height='40'>
        <title>Tablet Size K</title>
        <PillPathing as='circle' cx='19.1' cy='19.1' r='18.6' />
        <PillPathing d='M80.3,15.3c0-1.4-8.4-2.5-18.8-2.5c-10.4,0-18.8,1.1-18.8,2.5v7.5c0,1.4,8.4,2.5,18.8,2.5 c10.4,0,18.8-1.1,18.8-2.5V15.3z' />
      </Svg>
      }
      {pillSize === 'L' &&
      <Svg width='95' height='45'>
        <title>Tablet Size L</title>
        <PillPathing as='circle' cx='22' cy='22' r='21.5' />
        <PillPathing d='M71,13.5c-11.7,0-21.2,1.3-21.2,3v11.1c0,1.6,9.5,3,21.2,3s21.2-1.3,21.2-3V16.4 C92.2,14.8,82.7,13.5,71,13.5z' />
      </Svg>
      }
      {pillSize === 'M' &&
      <Svg width='108' height='52'>
        <title>Tablet Size M</title>
        <PillPathing as='circle' cx='25.1' cy='25.1' r='24.6' />
        <PillPathing d='M80.9,15.5c-13.4,0-24.3,1.7-24.3,3.7V31c0,2,10.9,3.7,24.3,3.7c13.4,0,24.3-1.7,24.3-3.7 V19.2C105.2,17.2,94.4,15.5,80.9,15.5z' />
      </Svg>
      }
      {pillSize === 'N' &&
      <Svg width='131' height='65'>
        <title>Tablet Size N</title>
        <PillPathing as='circle' cx='30.6' cy='30.6' r='30.1' />
        <PillPathing d='M129.3,35.8V25.4c0.2-0.2,0.3-0.4,0.3-0.5c0-2-13.5-3.7-30.1-3.7s-30.1,1.7-30.1,3.7v11.5 c0,2,13.5,3.7,30.1,3.7s30.1-1.7,30.1-3.7C129.6,36.2,129.5,36,129.3,35.8z' />
      </Svg>
      }
      {pillSize === 'P' &&
      <Svg width='110' height='65'>
        <title>Tablet Size P</title>
        <PillPathing as='ellipse' cx='18.4' cy='30.4' rx='17.9' ry='29.9' />
        <PillPathing d='M76.4,20.8c-16.5,0-29.8,1.7-29.8,3.7c0,0.1,0,0.2,0.1,0.3V36c-0.1,0.1-0.1,0.2-0.1,0.3 c0,2,13.4,3.7,29.8,3.7c16.5,0,29.8-1.7,29.8-3.7V24.5C106.2,22.5,92.9,20.8,76.4,20.8z' />
      </Svg>
      }
      {pillSize === 'Q' &&
      <Svg width='100' height='75'>
        <title>Tablet Size Q</title>
        <PillPathing d='M23.7,12.9C23.7,12.9,23.7,12.9,23.7,12.9c0-6.9-5.2-12.4-11.6-12.4S0.5,6,0.5,12.8v49 c0,6.8,5.2,12.3,11.6,12.3s11.6-5.5,11.6-12.3c0,0,0,0,0-0.1V12.9z' />
        <PillPathing d='M93.5,30.2c0-0.1,0.1-0.2,0.1-0.3c0-2.6-13.4-4.6-29.8-4.6S34,27.4,34,29.9v14.9 c0,2.6,13.4,4.6,29.8,4.6s29.8-2.1,29.8-4.6c0-0.1,0-0.2-0.1-0.3V30.2z' />
      </Svg>
      }
      {pillSize === 'R' &&
      <Svg width='110' height='75'>
        <title>Tablet Size R</title>
        <PillPathing d='M24.2,12.6c0-0.4,0.1-0.8,0.1-1.2c0-6-5.3-10.9-11.9-10.9C5.8,0.5,0.5,5.4,0.5,11.4v52.3 c0,6,5.3,10.9,11.9,10.9c6.6,0,11.9-4.9,11.9-10.9c0-0.4,0-0.8-0.1-1.2V12.6z' />
        <PillPathing d='M72.3,24c-19.8,0-35.8,2.4-35.8,5.4v16.4c0,3,16,5.4,35.8,5.4s35.8-2.4,35.8-5.4V29.3 C108.1,26.4,92.1,24,72.3,24z' />
      </Svg>
      }
      {pillSize === 'S' &&
      <Svg width='130' height='83'>
        <title>Tablet Size S</title>
        <PillPathing d='M26.5,14.8c0.1-0.5,0.1-0.9,0.1-1.4c0-7.1-5.8-12.8-13.1-12.8C6.4,0.5,0.5,6.2,0.5,13.3v56.3 c0,7.1,5.9,12.8,13.1,12.8c7.2,0,13.1-5.7,13.1-12.8c0-0.5,0-1-0.1-1.4V14.8z' />
        <PillPathing d='M122.6,28.1c0-0.1,0-0.2,0-0.3c0-3.8-18.5-6.8-41.4-6.8c-22.8,0-41.4,3-41.4,6.8v27.3 c0,3.8,18.5,6.8,41.4,6.8c22.8,0,41.4-3.1,41.4-6.8c0-0.1,0-0.2,0-0.3V28.1z' />
      </Svg>
      }
      {pillSize === 'T' &&
      <Svg width='140' height='91'>
        <title>Tablet Size T</title>
        <PillPathing as='circle' cx='45.5' cy='45.75' r='45' />
        <PillPathing d='M126,91a.72.72,0,0,0,.22,0c3,0,5.48-20.26,5.48-45.25S129.23.5,126.21.5H104.28c-3,0-5.48,20.26-5.48,45.25S101.25,91,104.28,91a.71.71,0,0,0,.22,0Z' />
      </Svg>
      }
      <PennySvg>
        <title>Penny for comparison</title>
        <circle fill='#DA8A67' stroke='#894D1F' strokeMiterlimit='10' cx='37.2' cy='37.2' r='36.7' />
        <path fill='#894D1F' d='M17.3,30v-3.9c1.8-0.1,3.1-0.2,3.8-0.4c1.1-0.3,2.1-0.8,2.8-1.5c0.5-0.5,0.9-1.2,1.1-2.1 c0.1-0.5,0.2-0.9,0.2-1.2H30v29h-5.8V30H17.3z' />
        <path fill='#894D1F' d='M41.4,30.9c1.9-2.1,4.2-3.2,7.1-3.2c0.6,0,1,0,1.3,0c0.2,0,0.6,0.1,1,0.2l1-3.6h2.1l-1.2,4 c1.4,0.5,2.6,1.2,3.5,2.1c1.4,1.4,2.2,3.2,2.5,5.6h-5.8c-0.1-0.8-0.4-1.5-0.7-2c-0.2-0.3-0.5-0.6-0.8-0.9l-3.5,12.7 c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.3,0,0.5,0c0.9,0,1.7-0.3,2.5-0.9c0.7-0.6,1.2-1.6,1.4-3h5.8c-0.2,2.5-1.1,4.6-2.7,6.2 c-1.6,1.7-4.1,2.5-7.6,2.5c-0.4,0-0.7,0-1,0s-0.6-0.1-1-0.1l-1.2,4.2h-2.2l1.5-4.8c-1.7-0.6-3.1-1.9-4.3-3.7 c-1.2-1.8-1.7-4.1-1.7-6.9C38.6,35.8,39.5,33,41.4,30.9z M48.8,32.5c-1.3,0-2.4,0.7-3.1,2c-0.7,1.4-1.1,3-1.1,4.8 c0,1.6,0.2,2.8,0.5,3.7c0.3,0.9,0.7,1.5,1,1.8l3.4-12.3C49.2,32.5,49,32.5,48.8,32.5z' />
      </PennySvg>
    </PillSizeContainer>
  )
}

const Svg = styled(SvgAttr)``

const PennySvg = styled(SvgAttr)`
  width:75px;
  height: 75px;
`

const PillSizeContainer = styled.div`
  width: 320px;
  height: 550px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${props => props.theme.breakpoints.sm}){
    width: 230px;
    height: 320px;
  }
`

const PillPathing = styled.path`
  fill: ${props => props.theme.mediumGrey};
  stroke: ${props => props.theme.darkGrey};
  stroke-width: .1rem;
`
