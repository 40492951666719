import React from 'react'
import styled, { css } from 'styled-components'
import { LazyImage } from '../LazyImage/LazyImage'
import { ReviewRating } from '../ReviewRating/ReviewRating'
import { AddToCartButton } from '../_inputs/AddToCartButton/AddToCartButton'
import { VisuallyHidden } from '../_styled/VisuallyHidden'

export const ProductCard = ({
  focus = false,
  product,
  additionalAfterAddToCartCode,
  isCarousel = false,
  updateCart,
  displayATCBanner,
  productCardStyles,
}) => {
  let flagColor = ''
  if (product.newWebItem) {
    flagColor = '#23350D'
  } else if (product.bogo) {
    flagColor = '#B93E09'
  } else if ((product?.flags?.indexOf('new_improved') !== -1) || (product?.flagMap?.new_improved)) {
    flagColor = '#23350D'
  } else {
    flagColor = '#fff'
  }

  return (
    <ProductCardContainerDiv>
      <ProductCardBorderDiv
        data-cnstrc-item = "recommendation"
        data-cnstrc-item-id={product?.constructorOptions?.item_id}
        data-cnstrc-item-name={product?.constructorOptions?.item_name}
        data-cnstrc-item-variation-id={product?.constructorOptions?.variationId}
        data-cnstrc-strategy-id={product?.constructorOptions?.strategy_id}
        data-cnstrc-item-price={product?.productDiscountPrice}
        styles={productCardStyles}>
        <ProductCardHalfDiv styles={css`margin-right: 1.9rem; width: 11.3rem;`}>
          <LeftProductCardA
            id={focus ? 'productFocus' : false}
            href={`/${product?.longURL}`}
            title={`${product?.productName} - ${product?.productPartNumber}`}
          >
            <ProductCardTextDiv className='leftAnchorHover' aria-label={product?.productVendor}>{product?.productVendor.toUpperCase()}</ProductCardTextDiv>
            <ProductCardImageDiv>
              <LazyImage
                src={`https://media.swansonvitamins.com/images/items/250/${product?.productPartNumber}.jpg`}
                alt={`${product?.productVendor} ${product?.productName}`}
              />
            </ProductCardImageDiv>
            <ProductCardTextDiv className='leftAnchorHover' aria-label={product?.productDetails}>{product?.productDetails}</ProductCardTextDiv>
          </LeftProductCardA>
        </ProductCardHalfDiv>
        <ProductCardHalfDiv styles={css`width: 13.4rem;`}>
          <ProductCardDiscountDiv>
            {product?.percentDiscount > 0 &&
            <ProductCardTextP>{Math.floor(product?.percentDiscount * 100)}%&nbsp;<span aria-label='off'>OFF</span></ProductCardTextP>
            }
            {product?.bogo &&
            <ProductCardTextP>BOGO</ProductCardTextP>
            }
          </ProductCardDiscountDiv>
          <ProductCardA
            href={`/${product?.longURL}`}
            title={`${product?.productName} - ${product?.productPartNumber}`}
            styles={css`
              font-size: 1.4rem;
              text-align: center;
              justify-content: center;
            `}
          >
            {product?.productName}
          </ProductCardA>
          <ReviewRating
            productUrl={product?.longURL}
            styles={css`
              color: #2F711C;
              justify-content: center;
            `}
            beFirstStyles={css`
              text-align: center;
              color: #2F711C;
            `}
            starStyles={css`
              width: 8.3rem;
              margin-right: 0.2rem;
            `}
            starTextStyles={css`
              font-size: 1.2rem;
            `}
            rating={product?.rating}
            ratingCount={product?.numReviews}
          />
          <ProductCardPriceDiv styles={product?.bogo ? css`justify-content: center` : ''}>
            {product?.bogo &&
              <ProductCardBogoTextDiv>2 for&nbsp;{product?.productDiscountPrice}</ProductCardBogoTextDiv>
            }
            {(product?.percentDiscount > 0 && !product?.bogo && !product?.tooLowToShow) &&
              <>
                <ProductCardPercentDiscountDiv styles={css`text-decoration-line: line-through;`}><VisuallyHidden>retail price</VisuallyHidden>${product?.everyDayLowPrice}</ProductCardPercentDiscountDiv>
                <ProductCardPercentDiscountDiv styles={css`color: #00833d;`}><VisuallyHidden>sale price</VisuallyHidden>${product?.productDiscountPrice}</ProductCardPercentDiscountDiv>
              </>
            }
            {!(product?.percentDiscount > 0 || product?.bogo) &&
              <ProductCardNoDiscPriceDiv><VisuallyHidden>price</VisuallyHidden>${product?.productDiscountPrice}</ProductCardNoDiscPriceDiv>
            }
            {product?.tooLowToShow &&
              <TooLowToShow>
                Save {Math.floor(product?.percentDiscount * 100)}% in cart
              </TooLowToShow>
            }
          </ProductCardPriceDiv>
          <AddToCartButton
            data-cnstrc-btn="add_to_cart"
            product={product}
            additionalAfterAddToCartCode={additionalAfterAddToCartCode}
            displayATCBanner={displayATCBanner}
            isCarousel={isCarousel}
            updateCart={updateCart}
            styles={css`margin: 0;`}
            ageVerifyRequired={(product?.flags?.indexOf('age_verification') > -1) || (product?.flagMap?.age_verification)}
          />
        </ProductCardHalfDiv>
        {(product?.newWebItem || product?.bogo || !!product?.flags?.indexOf('new_improved') > 0 || (product?.flagMap?.new_improved) || (product?.rewards2x || product?.rewards3x || product?.rewards4x)) &&
          <ProductCardFlagContainerDiv flagColor={(product?.rewards2x || product?.rewards3x || product?.rewards4x) ? '#23350D' : flagColor} styles={(product?.flags?.indexOf('new_improved') > 0) || (product?.flagMap?.new_improved) ?
            (css`
              display: flex;
              width: 15.5rem;
              top: -3rem;
              right: -6rem;
              height: 9rem;
              padding-bottom: 0.2rem;
              align-items: end;
            `) : ((!(product?.flags?.indexOf('new_improved') > 0) || (product?.flagMap?.new_improved)) && !(product?.rewards2x || product?.rewards3x || product?.rewards4x)) ? (css`
              display: flex;
              top: -1.3rem;
              right: -4.5rem;
              width: 12.5rem;
              height: 6rem;
              padding-bottom: 0.7rem;
              align-items: end;
            `): (product?.rewards2x || product?.rewards3x || product?.rewards4x) ? (css`
              display: flex;
              flex-direction: column;
              align-items: center;
              top: -1.9rem;
              right: -5.8rem;
              width: 16rem;
              height: 7.5rem;
              text-align: center;
              padding-top: 2rem;
              z-index: 20;
            `) : ''}>
            {product.newWebItem &&
              <ProductCardTextP>New!</ProductCardTextP>
            }
            {product?.bogo &&
              <ProductCardTextP>BOGO!</ProductCardTextP>
            }
            {(product?.flags?.indexOf('new_improved') > 0 || (product?.flagMap?.new_improved)) &&
              <ProductCardTextP styles={css`
              line-height: 1.2;
              text-align: center;
              `}
              >New &<br />Improved!</ProductCardTextP>
            }
            {((product?.rewards2x || product?.rewards3x || product?.rewards4x)) &&
              <>
                <ProductCardTextP
                  styles={css`
                    font-size: 2.5rem;
                    font-weight: 600;
                    line-height: 100%
                  `}
                >
                  {product?.rewards2x && '2X'}
                  {product?.rewards3x && '3X'}
                  {product?.rewards4x && '4X'}
                </ProductCardTextP>
                <ProductCardTextP
                  styles={css`
                    font-size: 1.2rem;
                    font-weight: 600;
                  `}
                >Rewards Points</ProductCardTextP>
              </>
            }
          </ProductCardFlagContainerDiv>
        }
      </ProductCardBorderDiv>
    </ProductCardContainerDiv>
  )
}

const ProductCardContainerDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
`

const ProductCardBorderDiv = styled.div`
  display: flex;
  width: 33.3rem;
  height: 25.2rem;
  border-radius: 1rem;
  border: 1px solid ${props => props.theme.mediumGrey};
  box-shadow: 0 4px 4px ${props => props.theme.mediumGrey};
  padding: 3.3rem 2.7rem 3rem 2.9rem;
  position: relative;
  overflow: hidden;
  ${props => props.styles};
`

const ProductCardHalfDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  align-content: space-between;
  ${props => props.styles};
`

export const ProductCardA = styled.a`
  display: flex;
  width: 100%;
  text-decoration: none;
  color: ${props => props.theme.black};
  line-height: 1.6rem;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
    color: ${props => props.theme.black};
  }
  ${props => props.styles};
`

export const LeftProductCardA = styled.a`
  display: flex;
  width: 100%;
  text-decoration: none;
  color: ${props => props.theme.black};
  line-height: 1.6rem;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-between;
  height: 100%;
  padding-right: 0.2rem;
  
  .leftAnchorHover {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
      color: ${props => props.theme.black};
    }
  }
  ${props => props.styles};
`

export const ProductCardTextDiv = styled.div`
  font-size: 1.2rem;
  width: 100%;
  text-align: center;
  ${props => props.styles};
`

export const ProductCardImageDiv = styled.div`
  display: flex;
  justify-content: center;
`

export const ProductCardDiscountDiv = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 1.4rem;
  height: 2.4rem;
`

export const ProductCardPriceDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const ProductCardPercentDiscountDiv = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 700;
  ${props => props.styles};
`

export const ProductCardBogoTextDiv = styled.div`
  font-size: 1.8rem;
  font-weight: 700;
  color: ${props => props.theme.darkRed};
`

export const ProductCardNoDiscPriceDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: bold;
`

export const ProductCardFlagContainerDiv = styled.div`
  position: absolute;
  justify-content: center;
  top: -1.3rem;
  right: -4.5rem;
  width: 12.5rem;
  height: 6rem;
  transform: rotate(45deg);
  z-index: 10;
  color: ${props => props.theme.white};
  background-color: ${props => props.flagColor};
  ${props => props.styles};
`

export const ProductCardTextP = styled.p`
  margin: 0;
  ${props => props.styles};
`
export const TooLowToShow = styled.div`
 color: ${props => props.theme.darkRed};
 line-height: 2.0rem;
`
